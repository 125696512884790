import React, { Fragment, useEffect, useRef, useState } from "react";
import { webLinks } from "../components/Layout/WebsiteLinks";
import "../styles/home.scss";
import ProductCard from "./product/ProductCard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProductErrors,
  getAllProductAction,
  // getAllProductAction,
} from "../redux/action/productAction";
import CustomButton from "../components/reusable/CustomButton";
import Header from "../components/Layout/Header";
import {
  aboutUs,
  handcrafted,
  logo,
  noAdditives,
  purity,
  supportFarmer,
  transparent,
} from "../assests/imageModule/image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import TestimonialSlider from "../components/Testimonials";
import Loader from "../components/Loader/Loader";
import BannerSlider from "../components/reusable/ReusableSlider";
import {
  addCartAction,
  clearCartErrors,
  clearCartState,
  getAllCartitemAction,
} from "../redux/action/cartAction";
import { useAuth } from "../context/userContext";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Home = () => {
  const [auth] = useAuth();
  // ANIMATION CARDS USING GSAP
  const cardsRef = useRef([]);

  useEffect(() => {
    gsap.from(cardsRef.current, {
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.3,
      ease: "power3.out",
      scrollTrigger: {
        trigger: ".why_saaj_farms",
        start: "top bottom",
        end: "bottom top",
        toggleActions: "play none none none",
      },
    });
  }, []);

  // ABOUT US ANIMATIONS

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // HANDLE PRODUCTS ACTION STARTS
  const { error, loading, products } = useSelector(
    (state) => state.getAllProduct
  );

  useEffect(() => {
    dispatch(getAllProductAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearProductErrors());
    }
  }, [dispatch, error, products?.success, products?.message]);

  // HANDLE PRODUCTS ACTION ENDS

  // CART ITEMS LIST STARTS

  const {
    loading: cartListLoading,
    error: cartListError,
    cartItems: cartList,
  } = useSelector((state) => state.getAllCartItem);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getAllCartitemAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (cartListError) {
      dispatch(clearCartErrors());
    }
  }, [cartListError, dispatch]);

  // CART ITEMS LIST ENDS

  // HANDLE ADD CART ACTION STARTS
  const [loadingId, setLoadingId] = useState(null);
  const {
    loading: cartLoading,
    error: cartError,
    cartItems,
  } = useSelector((state) => state.addCartItem);

  const handleAddToCart = (item) => {
    if (
      cartList?.cart?.some((cartItem) => cartItem?.product?._id === item?._id)
    ) {
      navigate("/shopping-cart");
    } else {
      const numberOfItems = 1;
      setLoadingId(item?._id);
      dispatch(addCartAction(item?._id, numberOfItems, item?.availableSize[0]));
    }
  };

  useEffect(() => {
    if (cartError) {
      toast.error(cartError);
      dispatch(clearCartErrors());
      setLoadingId(null);
    }
    if (cartItems?.success) {
      toast.success(cartItems?.message);
      dispatch(clearCartState());
      setLoadingId(null);
      navigate("/shopping-cart");
    }
  }, [cartError, cartItems, dispatch, navigate]);

  // HANDLE ADD CART ACTION ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          <section className="banner_slider">
            <BannerSlider />
          </section>
          <section className="welcome_farms">
            <div className="container">
              <div className="title">
                <h2>Welcome To Saaj Farms</h2>
                <div className="sub_title">
                  <p>
                    Where taste meets health in every jar, with authentic
                    flavours and natural goodness straight from our farms to
                    your table
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="log_highlight">
            <div className="container">
              <div className="card_logo">
                <div className="img_wrap">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>
          </section>

          <section className="products">
            <div className="container">
              <div className="products_list">
                <div className="title">
                  <h2>Our Products</h2>
                </div>
                <div className="product_listing">
                  {products?.products?.map((ele) => {
                    const isInCart = cartList?.cart?.some(
                      (cartItem) => cartItem.product?._id === ele?._id
                    );
                    return (
                      <ProductCard
                        key={ele?._id}
                        id={ele?._id}
                        title={ele?.title}
                        price={ele?.originalPrice}
                        discountPrice={ele?.discountedPrice}
                        rating={ele?.rating}
                        description={ele?.description}
                        numOfReviews={ele?.numOfReviews}
                        cartLoading={cartLoading}
                        checkCart={isInCart}
                        loadingId={loadingId}
                        onCartAdd={() => handleAddToCart(ele)}
                        onClick={() => navigate(`product-detail/${ele?._id}`)}
                      />
                    );
                  })}
                </div>

                <div className="view_more_btn">
                  <CustomButton
                    title="Explore More"
                    width="140px"
                    onClick={() => navigate("/products")}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="why_saaj_farms">
            <div className="container">
              <div className="title">
                <h2>Why SAAJ FARMS</h2>
              </div>
              <div className="card_sec">
                <div className="card">
                  <img src={purity} alt="card_bg" />
                  <p>Purity</p>
                </div>
                <div className="card">
                  <img src={transparent} alt="card_bg" />
                  <p>Transpareancy</p>
                </div>
                <div className="card">
                  <img src={handcrafted} alt="card_bg" />
                  <p>Handcrafted with care</p>
                </div>
                <div className="card">
                  <img src={supportFarmer} alt="card_bg" />
                  <p>Support for local farmers</p>
                </div>
                <div className="card">
                  <img src={noAdditives} alt="card_bg" />
                  <p>No Artificial Additives</p>
                </div>
              </div>
            </div>
          </section>

          {/* ABOUT US SECTION STARTS  */}
          <section className="about_us">
            <div className="title">
              <h2>About Us</h2>
            </div>
            <div className="stry">
              <img src={aboutUs} alt="about" />
              <div className="content">
                <div>
                  <p>
                    With a newfound sense of purpose, We started Saaj Farms to
                    bring the simple, authentic flavors of our village life to
                    your home. Our ghee is made the traditional way, with milk
                    from happy, healthy cows, and our honey is pure and natural,
                    straight from the hive.
                  </p>
                  <br />
                  <p>
                    Saaj Farms is more than just a brand—it’s a bridge between
                    the simplicity of rural life and the needs of today’s city
                    dwellers. Experience the taste of authenticity, crafted with
                    love and tradition.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="testimonials_section">
            <div className="container">
              <TestimonialSlider />
            </div>
          </section>

          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Home;
