import axios from "axios";
import {
  CLEAR_CANCEL_ORDER_STATE,
  CLEAR_ORDER_ERRORS,
  CLEAR_ORDER_STATE,
  CLEAR_UPDATE_ORDER_STATE,
  GET_ORDER_INVOICE_FAIL,
  GET_ORDER_INVOICE_REQUEST,
  GET_ORDER_INVOICE_SUCCESS,
  GET_ORDER_LIST_FAIL,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
} from "../constant/orderConstant";

// CREATE NEW ORDER BY USER  STARTS
export const orderCreateAction =
  (
    items,
    createdBy,
    paymentMethod,
    shippingAddress,
    shippingMethod,
    discount,
    shippingCost,
    totalPrice,
    deliveryDate
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_CREATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("saajToken")
        ? JSON.parse(localStorage.getItem("saajToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
        withCredentials: true
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_LIVE}/create-order`,
        {
          items,
          createdBy,
          paymentMethod,
          shippingAddress,
          shippingMethod,
          discount,
          shippingCost,
          totalPrice,
          deliveryDate,
        },
        config
      );
      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CREATE NEW ORDER BY EDNS

// GET USER ALL ORDER LIST  START
export const getAllOrderAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("saajToken")
      ? JSON.parse(localStorage.getItem("saajToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
      withCredentials: true
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_LIVE}/my-orders`,
      config
    );

    dispatch({
      type: GET_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};
//   GET USER ALL ORDER LIST ENDS


// DOWNLOAD INVOICE STARTS 
export const downloadOrderInvoice = (id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ORDER_INVOICE_REQUEST,
      });
  
      const localStorageToken = localStorage.getItem("saajToken")
        ? JSON.parse(localStorage.getItem("saajToken"))
        : "";
  
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;
  
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
        withCredentials: true
      };
  
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_LIVE}/order-invoice/${id}`,
        config
      );
  
      dispatch({
        type: GET_ORDER_INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_INVOICE_FAIL,
        payload: error.response.data.message,
      });
    }
  };
// DOWLOAD INVOICE ENDS 

export const clearOrderError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_ERRORS });
};
export const clearOrderState = () => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_STATE });
};
export const clearUpdateOrderState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_ORDER_STATE });
};
export const clearCancelOrderState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CANCEL_ORDER_STATE });
};
