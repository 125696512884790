import React, { Fragment } from "react";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
import Header from "../../components/Layout/Header";
const RefundPolicy = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />
      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Refund Policy</h4>
            <p>(Last Updated: 07-October-2024)</p>
          </div>
          <p>
            At <strong>Saaj Farms</strong>, we are committed to delivering
            high-quality ghee and honey products that reflect the authentic
            taste and purity of nature. Due to the perishable nature of our
            products, we have established the following Refund and Return Policy
            to ensure customer satisfaction while maintaining health and safety
            standards.
          </p>

          <div className="body_txt">
            <ul>
              <li>
                <strong>No Returns:</strong> We do not accept returns on our
                ghee and honey products due to their perishable nature and in
                order to maintain quality standards. Once the product has been
                delivered, it cannot be returned.
              </li>
            </ul>
          </div>
          <div className="body_txt">
            <ul>
              <li>
                <strong>Damaged or Defective Products:</strong> If you receive a
                damaged or defective product, please contact us within 2 days of
                receiving your order. We may ask for a photo of the damaged item
                to help us address the issue effectively. In cases of confirmed
                damage or defect, we may offer a refund,
                depending on the circumstances.
              </li>
              <li>
                <strong>Incorrect Items:</strong> If you receive an incorrect
                item, please notify us within 2 days of receiving the order. We
                will arrange for the correct product to be delivered to you, or
                we may issue a refund if the item is unavailable.
              </li>

              <li>
                <strong>Refund Process:</strong> To request a refund, please
                contact us at help@saajfarms.com with your order number, a
                description of the issue, and any supporting photos, if
                applicable. Refunds are generally processed within 7 business
                days after approval and may take additional time to reflect on
                your original payment method, depending on your bank’s
                processing times.
              </li>
            </ul>
          </div>
          <br />
          <br />
          <p>
            We do not offer exchanges on our products. In cases where a product
            is damaged, defective, or incorrect, we will provide a replacement
            or refund as described above.
          </p>
          <br />
          <br />
          <p>
            If you have any questions or need assistance with your order, please
            contact our customer support team
          </p>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default RefundPolicy;
