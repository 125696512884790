import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Layout/Header";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearProductErrors,
  getProductDetailsAction,
} from "../../redux/action/productAction";
import Footer from "../../components/Layout/Footer";
// import BreadCrumb from "../../components/BreadCrumb";
import { FaStar } from "react-icons/fa";
import {
  fssai,
  labTest,
  microscope,
  natural,
  testimonial1,
} from "../../assests/imageModule/image";
import "../../styles/productDetail.scss";
import { PiCurrencyInrBold } from "react-icons/pi";
import { faq } from "../../components/Layout/faq";
import {
  addCartAction,
  clearCartErrors,
  clearCartState,
} from "../../redux/action/cartAction";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useAuth } from "../../context/userContext";

const ProductDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState(null);
  const [numberOfItems, setNumberOfItems] = useState(1);
  const navigate = useNavigate();
  const [auth] = useAuth();
  const { loading, error, product } = useSelector(
    (state) => state.getProductDetail
  );

  useEffect(() => {
    if (id) {
      dispatch(getProductDetailsAction(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearProductErrors());
    }
    if (product?.success) {
      setSelectedSize(product?.product?.availableSize[0]);
    }
    // eslint-disable-next-line
  }, [dispatch, error, product?.success]);

  const data = product?.product;

  const handleAddToCart = (id) => {
    if (auth?.user?._id) {
      dispatch(addCartAction(id, numberOfItems, selectedSize));
    } else {
      navigate("/authentication");
    }
  };

  const {
    error: cartError,
    loading: addCartLoading,
    cartItems,
  } = useSelector((state) => state.addCartItem);

  useEffect(() => {
    if (cartError) {
      // toast.error(cartError);
      dispatch(clearCartErrors());
    }
    if (cartItems?.success) {
      toast.success(cartItems?.message);
      dispatch(clearCartState());
    }
  }, [dispatch, cartItems?.success, cartItems?.message, cartError]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          {/* <BreadCrumb pageTitle={data?.title} /> */}
          <div className="container">
            <section className="product_details_wrapper">
              <div className="left_sec">
                <img src={data?.images[0]} alt="main_image" />
              </div>
              <div className="right_sec">
                <h2>{data?.title}</h2>
                <div className="rating">
                  <span>
                    <FaStar /> 4.6
                  </span>
                </div>
                <div className="avaiblity">
                  <strong>Availability:</strong>
                  <span>
                    {data?.stockQuantity > 0 ? "In Stcok" : "Out of stock"}
                  </span>
                </div>
                <div className="price">
                  {data?.discountedPrice !== 0 &&
                  data?.discountedPrice !== null ? (
                    <>
                      <strike>
                        <PiCurrencyInrBold /> {data?.originalPrice}
                      </strike>
                      <strong>
                        <PiCurrencyInrBold /> {data?.discountedPrice}
                      </strong>
                      <div className="offer">
                        <p>
                          {(
                            ((data?.originalPrice - data?.discountedPrice) /
                              data?.originalPrice) *
                            100
                          ).toFixed(0)}
                          % off
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <strong>
                        <PiCurrencyInrBold /> {data?.originalPrice}
                      </strong>
                    </>
                  )}
                </div>
                <strong>MRP. Incl of all taxes</strong>
                <ul className="size">
                  <p>Size :</p>
                  {data?.availableSize?.map((ele, id) => {
                    return (
                      <button
                        className={selectedSize === ele ? "active" : ""}
                        key={id}
                        onClick={() => setSelectedSize(ele)}
                      >
                        {ele}
                      </button>
                    );
                  })}
                </ul>
                <div className="checkout">
                  <div className="quantity">
                    <p>Choose Quantity :</p>
                    <button
                      onClick={() =>
                        setNumberOfItems(Math.max(numberOfItems - 1, 1))
                      }
                      disabled={numberOfItems <= 1}
                    >
                      -
                    </button>
                    <span>{numberOfItems}</span>
                    <button onClick={() => setNumberOfItems(numberOfItems + 1)}>
                      +
                    </button>
                  </div>
                  <button
                    className="cart_add_btn"
                    onClick={() => handleAddToCart(data?._id)}
                  >
                    {addCartLoading ? <ButtonLoader /> : "Add To cart"}
                  </button>
                </div>

                <ul className="labels">
                  <li>
                    <span>
                      <img src={natural} alt="natural" />
                    </span>
                    <p>100% Natural</p>
                  </li>
                  <li>
                    <span>
                      <img src={labTest} alt="labTest" />
                    </span>
                    <p>No added chemicals</p>
                  </li>
                  <li>
                    <span>
                      <img src={fssai} alt="fssai" />
                    </span>
                    <p>Fssai approved</p>
                  </li>
                  <li>
                    <span>
                      <img src={microscope} alt="microscope" />
                    </span>
                    <p>Lab-tested</p>
                  </li>
                </ul>
              </div>
            </section>
            <section className="faq_list">
              {faq.map((faq, index) => (
                <div className="faq" key={index}>
                  <h4>{faq.question}</h4>
                  <p>{faq.answer}</p>
                </div>
              ))}
            </section>
            <section className="rating_wrapper">
              <div className="title">
                <h2>Customer Reviews</h2>
              </div>
              <div className="rating_list">
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
                <div className="user_rating">
                  <div className="title">
                    <img src={testimonial1} alt="rating_img" />
                    <div className="details">
                      <p>Rahul Yadav</p>
                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                  </div>
                  <p>
                    I recently purchase a2 cow ghee and taste is soo much good
                  </p>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default ProductDetail;
