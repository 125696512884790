import React, { Fragment } from "react";
import Footer from "../../components/Layout/Footer";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Header from "../../components/Layout/Header";

const TermsCondition = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />

      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Terms & Conditions</h4>
            <p>(Last Updated: 10-Nov-2024)</p>
          </div>{" "}
          <div className="shipping_txt">
            <p>
              This website is operated by Saaj Farms (also referred to as "we,"
              "us," and "our"). By accessing or using our website, including all
              information, tools, and services available, you agree to be bound
              by the following terms and conditions ("Terms of Service,"
              "Terms"). If you do not agree with these Terms, you may not access
              the website or use our services.
            </p>
            <ol>
              <li>
                <p>
                  Acceptance of Terms By using this site, you engage in our
                  "Service" and agree to be bound by these Terms. Any new
                  features or tools added to the current store shall also be
                  subject to these Terms. We reserve the right to update or
                  change these Terms at any time, and it is your responsibility
                  to review them periodically. Your continued use of the website
                  following the posting of any changes constitutes acceptance of
                  those changes.
                </p>
              </li>

              <li>
                <p>
                  Online Store Terms By agreeing to these Terms, you represent
                  that you are of legal age in your jurisdiction or have given
                  consent for your minor dependents to use this site. You may
                  not use our products for any illegal or unauthorized purpose,
                  nor may you violate any laws in your jurisdiction while using
                  our Service.
                </p>
              </li>

              <li>
                <p>
                  General Conditions We reserve the right to refuse service to
                  anyone for any reason at any time. Your content (excluding
                  credit card information) may be transferred unencrypted and
                  involve transmissions over various networks. Credit card
                  information is always encrypted during transfer. You agree not
                  to reproduce, duplicate, copy, sell, or exploit any portion of
                  the Service without express written permission.
                </p>
              </li>

              <li>
                <p>
                  Accuracy, Completeness, and Timeliness of Information We are
                  not responsible if information on this site is inaccurate,
                  incomplete, or not current. Materials are provided for general
                  information only and should not be relied upon as the sole
                  basis for making decisions. 6. Products or Services Certain
                  products may be available exclusively online. We strive to
                  display product colors and images accurately, but we cannot
                  guarantee your monitor's display will be accurate. We reserve
                  the right to limit quantities of products offered. 7. Accuracy
                  of Billing and Account Information We reserve the right to
                  refuse any order. You agree to provide accurate purchase and
                  account information and promptly update your account as
                  necessary.
                </p>
              </li>

              <li>
                <p>
                  Modifications to the Service and Prices Prices for our
                  products are subject to change without notice. We reserve the
                  right to modify or discontinue the Service at any time without
                  liability.
                </p>
              </li>
              <li>
                <p>
                  Products or Services Certain products may be available
                  exclusively online. We strive to display product colors and
                  images accurately, but we cannot guarantee your monitor's
                  display will be accurate. We reserve the right to limit
                  quantities of products offered.
                </p>
              </li>
              <li>
                <p>
                  Accuracy of Billing and Account Information We reserve the
                  right to refuse any order. You agree to provide accurate
                  purchase and account information and promptly update your
                  account as necessary.
                </p>
              </li>
              <li>
                <p>
                  Optional Tools We may provide access to third-party tools that
                  we do not monitor or control. Your use of these tools is at
                  your own risk.
                </p>
              </li>
              <li>
                <p>
                  Third-Party Links Our Service may include materials from third
                  parties. We are not responsible for examining or evaluating
                  the content or accuracy of these materials.
                </p>
              </li>
              <li>
                <p>
                  User Comments and Feedback If you send comments or suggestions
                  to us, we may use them without any obligation to maintain
                  confidentiality or compensate you.
                </p>
              </li>
              <li>
                <p>
                  Personal Information Your submission of personal information
                  through the store is governed by our Privacy Policy.
                </p>
              </li>
              <li>
                <p>
                  Errors, Inaccuracies, and Omissions We reserve the right to
                  correct any errors in product descriptions, pricing, or
                  promotions. We undertake no obligation to update information
                  unless required by law.
                </p>
              </li>
              <li>
                <p>
                  Prohibited Uses You are prohibited from using the site for
                  unlawful purposes, to harass or discriminate, to submit false
                  information, or to interfere with the Service's security
                  features.
                </p>
              </li>
              <li>
                <p>
                  Disclaimer of Warranties; Limitation of Liability We do not
                  guarantee uninterrupted or error-free service. Our liability
                  is limited to the maximum extent permitted by law.
                </p>
              </li>
              <li>
                <p>
                  Indemnification You agree to indemnify and hold harmless Saaj
                  Farms from any claims or demands arising out of your breach of
                  these Terms.
                </p>
              </li>
              <li>
                <p>
                  Severability If any provision of these Terms is found to be
                  unenforceable, the remaining provisions shall remain in
                  effect.
                </p>
              </li>
              <li>
                <p>
                  Termination These Terms are effective until terminated by
                  either party. We may terminate this agreement without notice
                  if you fail to comply with any provision of these Terms.
                </p>
              </li>
              <li>
                <p>
                  Entire Agreement These Terms constitute the entire agreement
                  between you and us and supersede any prior agreements.
                </p>
              </li>
              <li>
                <p>
                  Governing Law These Terms shall be governed by the laws of
                  India
                </p>
              </li>
              <li>
                <p>
                  Changes to Terms of Service We reserve the right to update or
                  change these Terms at any time. Your continued use of the
                  website constitutes acceptance of those changes.
                </p>
              </li>
              <li>
                <p>
                  Contact Information Questions about these Terms should be sent
                  to us at help@saajfarms.com.
                </p>
              </li>
            </ol>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default TermsCondition;
