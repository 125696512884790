import React, { Fragment, useEffect, useRef, useState } from "react";
import "../../styles/WebLayout.scss";
// import { logo } from "../../assests/imageModule/image";
import { BsBag } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { logo, logoMob } from "../../assests/imageModule/image";
import { mobLinks } from "./WebsiteLinks";
import { useAuth } from "../../context/userContext";
import { FaGoogle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/action/userAction";
const Header = ({ headerLinks }) => {
  const navRef = useRef();
  const [isFixed, setIsFixed] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleScroll = () => {
    const offsetTop = navRef?.current?.offsetTop;
    if (window.scrollY > offsetTop) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGoogleLogin = async () => {
    // window.open(`${process.env.REACT_APP_API_LIVE}/googlelogin`, "_self");
    navigate("/authentication");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  console.log(auth?.user);
  

  return (
    <Fragment>
      <section className="header_wrapper">
        <div className="t_head">
          <div className="container">
            <p>Get free delivery on order above 4999 or get 20% off</p>
          </div>
        </div>
        <div className={`m_head ${isFixed ? "fixed" : ""}`} ref={navRef}>
          <div className="container">
            <div className="header">
              <div className="logo" onClick={() => navigate("/")}>
                <img className="desk" src={logo} alt="logo" />
                <img className="mob" src={logoMob} alt="logo" />
              </div>
              <div className="sidebar_link desk">
                <div className="container">
                  <div className="header_links">
                    <ul>
                      {headerLinks?.map((itm) => (
                        <li key={itm?.id}>
                          <Link className="span" to={`${itm?.link}`}>
                            {itm?.icon}
                          </Link>
                          <Link to={`${itm?.link}`} className="txt_link">
                            {itm?.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="login_links">
                <ul>
                  <li>
                    <Link to="/shopping-cart">
                      <BsBag />
                    </Link>
                  </li>
                  <li>
                    {!auth?.user?._id ? (
                      <div
                        className="drop_down"
                        onClick={() => setShowLogin(!showLogin)}
                      >
                        <p>
                          <CiUser />
                        </p>
                        {showLogin && (
                          <div className="google_login active">
                            <button onClick={handleGoogleLogin}>
                              <FaGoogle /> Continue with google
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="drop_down"
                        onClick={() => setShowLogin(!showLogin)}
                      >
                        <p>
                          <Link to="/profile">
                            <CiUser />
                          </Link>
                        </p>
                        {showLogin && (
                          <div className="google_login">
                            <button onClick={handleLogout}>
                              <IoMdLogOut /> Logout
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sidebar_link mob">
        <div className="container">
          <div className="header_links">
            <ul>
              {mobLinks?.map((itm) => (
                <li key={itm?.id}>
                  <Link className="span" to={`${itm?.link}`}>
                    {itm?.icon}
                  </Link>
                  <Link to={`${itm?.link}`} className="txt_link">
                    {itm?.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Header;
