import axios from "axios";
import { axiosInstance } from "../../utils/config";
import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART_DELETE_STATE,
  CLEAR_CART_ERROR,
  CLEAR_CART_STATE,
  CLEAR_UPDATE_CART_STATE,
  GET_ALL_CART_FAIL,
  GET_ALL_CART_REQUEST,
  GET_ALL_CART_SUCCESS,
  REMOVE_TO_CART_FAIL,
  REMOVE_TO_CART_REQUEST,
  REMOVE_TO_CART_SUCCESS,
  UPDATE_CART_FAIL,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
} from "../constant/cartConstant";

export const getAllCartitemAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CART_REQUEST,
    });
    const localStorageToken = localStorage.getItem("saajToken")
      ? JSON.parse(localStorage.getItem("saajToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
      withCredentials: true
    };

    const { data } = await axiosInstance.get("/my-cart", config);

    dispatch({
      type: GET_ALL_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const addCartAction =
  (id, numberOfItems, variety) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_TO_CART_REQUEST,
      });
      const localStorageToken = localStorage.getItem("saajToken")
        ? JSON.parse(localStorage.getItem("saajToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
        withCredentials: true
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_LIVE}/add-to-cart/${id}`,
        {
          numberOfItems,
          variety,
        },
        config
      );

      dispatch({
        type: ADD_TO_CART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_TO_CART_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const updateCartItemAction = (id, numberOfItems) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CART_REQUEST,
    });
    const localStorageToken = localStorage.getItem("saajToken")
      ? JSON.parse(localStorage.getItem("saajToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
      withCredentials: true
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_LIVE}/increase-decrease-item/${id}`,
      {
        numberOfItems,
      },
      config
    );

    dispatch({
      type: UPDATE_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const removeCartAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_TO_CART_REQUEST,
    });
    const localStorageToken = localStorage.getItem("saajToken")
      ? JSON.parse(localStorage.getItem("saajToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
      withCredentials: true
    };

    const { data } = await axiosInstance.delete(
      `/remove-from-cart/${id}`,
      config
    );

    dispatch({
      type: REMOVE_TO_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_TO_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearCartErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CART_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearCartState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CART_STATE });
};

export const clearCartDeleteState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CART_DELETE_STATE });
};


export const clearCartUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_CART_STATE });
};
