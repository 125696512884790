import React, { Fragment } from "react";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Footer from "../components/Layout/Footer";
import "../styles/aboutUs.scss";
import BreadCrumb from "../components/BreadCrumb";
import {  buffalo, cow, honey } from "../assests/imageModule/image";
// import CustomButton from "../components/reusable/CustomButton";
const AboutUs = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />
      <BreadCrumb pageTitle="Our Story" />
      <section className="about_us">
       <div className="container">
       <div className="title">
          <h2>Our Story</h2>
        </div>

        <div className="stry_pages_wrap">
          <ul>
            <li>
              <h2>A Life in the City</h2>
              <p>
                In the heart of bustling Noida, where skyscrapers touch the
                clouds and the city hums with endless noise, my husband Manish
                and I are immersed in the fast-paced world of corporate jobs. We
                moved here in pursuit of better opportunities, leaving behind
                the peaceful rhythms of our village life. Though we appreciate
                the energy of city living, Manish often finds himself longing
                for the simple joys we left behind—the kind that comes from
                knowing exactly where our food comes from and savoring the true
                flavors of our land.
              </p>
            </li>
            <li>
              <h2>Roots in Rural Life</h2>
              <p>
                We both grew up in small villages of Haryana, where life
                revolved around family farms, and food was made with care and
                patience. Our villages are surrounded by green pastures and
                wide-open spaces, with a climate that’s ideal for nurturing
                happy, healthy cows. The fresh air and rich soil create an
                environment that supports our cows in producing pure, wholesome
                milk. Since childhood, we’ve watched the women in our family
                make ghee using the traditional Bilona method, churning fresh
                curd by hand and slowly simmering it until it turns golden. This
                age-old technique ensures the ghee is not only rich in flavor
                but also packed with nutrients—a true labor of love.
              </p>
            </li>
            <li>
              <h2>Longing for Home</h2>
              <p>
                Manish and I often find ourselves reminiscing about the familiar
                sights and smells of our village. The creamy texture of homemade
                ghee and the taste of honey, freshly harvested from bee hives,
                have always been integral parts of our lives. But here in the
                city, no matter where we shop, we struggle to find products that
                capture that same authenticity. The ghee and honey here may be
                labeled "pure," but they lack the deep, natural flavors we
                cherish.
              </p>
            </li>
            <li>
              <h2>A Spark of Inspiration</h2>
              <p>
                One evening, as Manish shares his longing for our village, an
                idea sparks within me. We could bridge the gap between our
                village and the city, bringing the essence of our village
                authentic, high-quality products to others. “Why not start a
                business?” I suggest. “We could make ghee the way our families
                did and offer honey that’s as pure and natural as what we grew
                up with.” I realize that we’re not the only ones craving
                this—many city dwellers long for products that reconnect them
                with nature and tradition.
              </p>
            </li>
            <li>
              <h2>The Birth of Saaj Farms</h2>
              <p>
                With a newfound sense of purpose, I started Saaj Farms. Our ghee
                is made with milk from our village cows, raised in the clean air
                and open spaces. We ensure every step of production is just as
                it was in our childhood: churning the curd by hand, simmering it
                patiently, and bottling the golden ghee that’s full of flavor
                and nutrition. Our honey, too, is sourced from bee hives,
                untouched by artificial processes, allowing the unique
                wildflower flavors to shine through.
              </p>
              <br />
              <p>
                By bringing Saaj Farms’ products, we hope to offer more than
                just ghee and honey. We want to share a piece of our home and
                heritage, so others can experience the real taste of the
                countryside and the values that shaped us.
              </p>
            </li>
          </ul>
        </div>
       </div>
      </section>
      <section className="about_us_blogs">
        <div className="container">
          <div className="title">
            <h2>About our Products</h2>
          </div>
          <div className="blog_cards">
            <div className="card">
              <div className="head">
                <img src={cow} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>A2 Cow Ghee</h4>
                <span>
                  At Saaj Farms, our A2 Cow Ghee is made from the pure milk of
                  our village's indigenous A2 cows, raised in the green pastures
                  of Haryana. Surrounded by clean air and lush fields, our cows
                  thrive in an environment that’s perfect for producing
                  high-quality milk. Following traditional methods, we carefully
                  churn fresh curd by hand and simmer it slowly, capturing the
                  golden essence of our heritage in each jar. This rich,
                  nutrient-packed ghee embodies the authentic taste and purity
                  of the countryside, preserving the traditional Bilona method
                  that’s been passed down for generations.
                </span>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <img src={buffalo} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>Buffalo Ghee</h4>
                <span>
                  Our Buffalo Ghee is crafted from the rich, creamy milk of
                  local buffaloes, nurtured in an environment ideal for their
                  well-being. The gentle climate and open fields allow our
                  buffaloes to produce milk that’s full-bodied and flavorful.
                  Following the same traditional Bilona method, we churn and
                  simmer the curd until it transforms into a smooth, golden ghee
                  that’s perfect for enhancing any meal. Saaj Farms’ Buffalo
                  Ghee delivers the taste and aroma that remind us of our
                  childhood kitchens, where food was prepared with patience and
                  care.
                </span>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <img src={honey} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>Pure Honey</h4>
                <span>
                  At Saaj Farms, we bring you a range of pure honey from
                  different regions of India, each with a unique taste that
                  reflects the local blossoms. From the gentle sweetness of
                  acacia honey from Kashmir to the light floral notes of litchi
                  honey from Bihar’s orchards, every variety embodies the
                  natural beauty of its origin. Each jar of Saaj Farms honey
                  offers a touch of nature’s goodness, capturing the simple,
                  pure flavors of the flowers that inspired it.
                </span>
              </div>
            </div>
          </div>
          {/* <div className="about_button">
            <CustomButton title="Explore" width="90px" />
          </div> */}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
