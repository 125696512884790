import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import { FaEnvelope, FaGoogle, FaLock } from "react-icons/fa";
import CustomInput from "../components/reusable/CustomInput";
import { useAuth } from "../context/userContext";
import { clearErrors, login } from "../redux/action/userAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../components/reusable/CustomButton";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  // const [otpSuccess, setOtpSuccess] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    mobile: "",
    fullName: "",
    otp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const { loading, error } = useSelector((state) => state.userLogin);

  const { email, password } = loginData;

  const handleLogin = () => {
    if (email && password) {
      dispatch(login(email, password));
    } else {
      toast.warning("Please enter mobile number.");
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (auth?.user?._id) {
      navigate("/profile");
    }
  }, [auth?.user?.role, auth?.user?._id, navigate]);

  // GOOGLE LOGIN

  const handleGoogleLogin = async () => {
    window.open(`${process.env.REACT_APP_API_LIVE}/googlelogin`, "_self");
  };

  return (
    <section className="login_wrapper">
      <div className="login_form">
        <div>
          <h1>
            Login To <span>Saaj Farms</span>
          </h1>
          <CustomInput
            icon={<FaEnvelope />}
            label="Email"
            type="email"
            value={loginData.email}
            name="email"
            placeholder="Enter Email"
            onChange={(e) => handleInputChange(e)}
          />
          <CustomInput
            label="Password"
            icon={<FaLock />}
            type={showPassword ? "text" : "password"}
            value={loginData.password}
            name="password"
            placeholder="Enter password"
            eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            setshowPassword={setshowPassword}
            onChange={(e) => handleInputChange(e)}
          />
          {/* {otpSuccess && (
            <CustomInput
              label="Enter OTP"
              type="number"
              icon={<FaLock />}
              value={loginData.otp}
              name="otp"
              placeholder="Enter otp"
              onChange={(e) => handleInputChange(e)}
            />
          )} */}
          <CustomButton
            onClick={handleLogin}
            width="100%"
            className="login_btn"
            title={loading ? <ButtonLoader /> : "Login"}
          />

          {/* <div className="forget">
            <label for="">
              <input type="checkbox" />
              Remember Me
            </label>
            {otpSuccess && <p>Resend Otp</p>}
          </div>
         
          {/* <div className="register">
              <p>
                Don't have a account{" "}
                <span onClick={() => setSwitchForm(2)}>Register</span>
              </p>
            </div> */}
          <div className="google_login">
            <strong>OR</strong>
            <button onClick={handleGoogleLogin}>
              <FaGoogle /> Continue with google
            </button>
          </div>
        </div>

        {/* {switchForm === 2 && (
          <div>
            <h1>
              Register To <span>Saaj Farms</span>
            </h1>
            <CustomInput
              icon={<FaUser />}
              label="Full Name"
              type="text"
              value={loginData.fullName}
              name="fullName"
              placeholder="Ex: Jhon Doe"
              onChange={(e) => handleInputChange(e)}
            />
            <CustomInput
              icon={<FaEnvelope />}
              label="Email"
              type="email"
              value={loginData.email}
              name="email"
              placeholder="Enter Email"
              onChange={(e) => handleInputChange(e)}
            />
            <CustomInput
              icon={<IoMdPhonePortrait />}
              label="Mobile"
              type="number"
              value={loginData.mobile}
              name="mobile"
              placeholder="Ex:98578XX86"
              onChange={(e) => handleInputChange(e)}
            />
            <CustomInput
              label="Password"
              icon={<FaLock />}
              type={showPassword ? "text" : "password"}
              value={loginData.password}
              name="password"
              placeholder="Enter password"
              eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              setshowPassword={setshowPassword}
              onChange={(e) => handleInputChange(e)}
            />

            <CustomButton
              onClick={handleLogin}
              width="100%"
              className="login_btn"
              title={loading ? <ButtonLoader /> : "Register"}
            />
            <div className="register">
              <p>
                Already have account{" "}
                <span onClick={() => setSwitchForm(1)}>Login</span>
              </p>
            </div>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Login;
